<template>
    <div>
        <h3>Pelatihan Anda</h3>
        <v-card flat class="">
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Gambar</th>
                            <th>Nama Pelatihan</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in table.items" :key="item.id">
                            <td>{{ index + 1 }}</td>
                            <td><v-img max-height="100" max-width="150" :src="item.gambar_url"></v-img></td>
                            <td>{{ item.nama }}</td>
                            <td>
                                <v-btn v-if="item.status == 'MENDAFTAR'" x-small color="error" @click="batalDaftar()">Batal</v-btn>
                                <router-link v-else :to="{ name: 'PemateriPelatihanDetail', params: { id: item.id } }">
                                    <v-btn title="Lihat" x-small color="success" class="ml-2">
                                        Lihat
                                    </v-btn>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil } from '@mdi/js'

export default {
    components: {
    },
    data() {
        return {
            icons: {
                mdiDelete,
                mdiPencil,
            },
            tableOptions: {},
            table: {
                loading: true,
                items: [],
                totalItems: 0,
            },
            form: {
                dialog: false,
                editedIndex: -1,
                editedItem: {
                    pembimbing_id: '',
                    tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
                    jam: new Date().toJSON().slice(11, 16).replace(/-/g, '-'),
                    tempat: '',
                    hasil_bst: '',
                },
                defaultItem: {
                    pembimbing_id: '',
                    tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
                    jam: new Date().toJSON().slice(11, 16).replace(/-/g, '-'),
                    tempat: '',
                    hasil_bst: '',
                },
                pembimbings: [],
            },
        }
    },
    watch: {
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
    },
    created() {
        this.getData()
        // this.getPembimbings()
    },
    methods: {
        getData() {
            axiosGetAuth('api/Pelatihan/getStatusPelatihanUser')
                .then(response => {
                    this.table.items = response.data
                    this.table.loading = false
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>